import React from 'react'
import img from "../../assets/chooseusimg.png"
const Teacher = () => {
  return (

    <div className='md:px-[5%]  xl:px-[6%] bg-[#F9F9F9]   sm:px-[6%] xs:px-[4%] lg:px-[7%] '>
<div className=''>
<div className='w-full xs:flex-col flex items-center'>
    <div className='w-1/2 xs:w-full'>
    <img src={img} alt="" />
    </div>
    <div className='flex w-1/2  xs:pb-4 xs:w-full flex-col px-10 xs:px-0 gap-4'>
<h1 className='text-[35px]  xs:text-[28px] sm:text-[30px] md:text-[33px]  font-semibold font-publicSans'>Are You a <span className='text-[#00A3E0]'>Teacher?</span> </h1>
<p className='text-[18px]  font-normal xl:text-[28px] lg:text-[24px] md:text-[22px] sm:text-[19px] xs:text-[17px] font-publicSans'>Learn Luxembourgish the Smart Way –  Join Our <br />  Community!</p>
<div>
<button className='px-6 py-2 bg-[#00A3E0]  text-white rounded-lg'>Join as a Teacher</button>

</div>
    </div>
</div>
    </div>
    </div>
    
  )
}

export default Teacher