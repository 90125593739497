import React from 'react'
import img from "../assets/Svg/choose.svg"
import img1 from "../assets/aboutus.png"
const AboutUs = () => {
  return (
    <div className='md:px-[5%]  xl:px-[6%] pt-[4%] pb-[3%]  sm:px-[6%] xs:px-[4%] lg:px-[7%] '>
         <div className='relative'>
            <h1 className='text-[35px] xl:text-[40px] xs:text-[28px] font-semibold font-publicSans'>About  <span className='text-[#00A3E0] '>Us</span></h1>
            <img src={img} alt=""  className='absolute  '/>
            <br />
        </div>
        <div className='w-full flex justify-between items-center sm:flex-col xs:flex-col '>
        <div className='w-[50%] xs:w-[100%] sm:w-[100%]'>
       
            <p className='text-[#6D737A] xl:text-[24px] lg:text-[22px]  text-[18px] sm:text-[18px] xs:text-[15px]  font-publicSans font-medium '>Welcome to Letztalk, the app designed to help you learn Luxembourgish the smart way! <br />
            <br />

At Letz Learn Together, we’re passionate about making language learning accessible, effective, and enjoyable. Whether you're new to Luxembourg or a resident looking to deepen your connection with the local culture, we're here to guide you every step of the way. <br /><br />

What sets us apart? Our lessons follow the official Luxembourgish language curriculum, ensuring you're learning exactly what you need to speak confidently and achieve your goals. Powered by advanced AI technology, Letztalk adapts to your learning style, helping you progress faster with personalized exercises and real-time feedback. <br /><br /></p>
        </div>
        <div className='w-[50%]  xs:w-[100%] sm:w-[100%]'>
            <div className='flex justify-center'>
            <img src={img1} alt=""  className='xl:w-[80%] xs:w-[70%] lg:w-[90%]  sm:w-[70%] w-[80%]'/>

            </div>
        </div>
        </div>
      
    </div>
  )
}

export default AboutUs