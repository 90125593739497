// apiService.js
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'; // for redirect if needed

const apiService = async (url, method, data = {}, headers = {}, history = null) => {
    try {
        const response = await axios({
            url,
            method,
            data,
            headers,
        });

        if (response.status === 200) {
            return response.data;
        }
    }catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
              
                toast.error('Session expired, please log in again.');
                window.location.reload();
            if (history) {
                history.push('/login'); // Assuming you're using React Router
            } // assuming you're using React Router
            } else {
            console.log(error);
            toast.error('Something went wrong. Please try again.');
        }
    }
};

export default apiService;
