import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import { IoChatbubbleSharp } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import image from "../../src/assets/images.png";
import image2 from "../../src/assets/logo.png";
import mic from "../../src/assets/mic.png";
import { Link } from "react-router-dom";
import NoteContext from "./Context/notes/NoteContext";
import apiService from "./Commonapi/AppServer";
import { toast } from "react-toastify";
import { FaBars, FaUser } from "react-icons/fa";
import example from "../assets/Svg/example.svg";
import plus from "../assets/Svg/Plusicon.svg";
import mike from "../assets/Svg/mikeicon.svg";
import gallery from "../assets/Svg/gallery.svg";
import send from "../assets/Svg/send.svg";
import Navbar from "./Main/Navbar";
import { FaBookmark } from "react-icons/fa";
import chatlogo from "../assets/Svg/chatlogo.svg"
import logo from "../assets/mainLogo.png";
// const socket = io.connect("http://localhost:4000");
const socket = io(process.env.REACT_APP_BASE_URL, {
  transports: ["websocket"],
  auth: { token: localStorage.getItem("token") },
});

const Chat = () => {
  const [isOpenn, setIsOpen] = useState(false); // State to handle the sidebar toggle

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [language, setLanguage] = useState("en");
  const params = useParams();

  const [id, setId] = useState(params.id);
  const [chapter, setChapter] = useState("");
  const [messages, setMessages] = useState([]);
  const [allChats, setAllChats] = useState([]);
  const [input, setInput] = useState("");
  const ref = useRef();
  const navigate = useNavigate();
  const { currentUser } = useContext(NoteContext);
 const sidebarRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false); // Track the pause state

  const [isListening, setIsListening] = useState(false);
  const shouldFetch = useRef(true);

  const toggleMenu = () => {
    setIsOpen(!isOpenn);
  };

 useEffect(() => {
    function handleOutsideClick(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function formatQuizOrReturnNormal(response) {
    // Patterns for detection
    const quizQuestionPattern = /^\d+\.\s.*$/m; // Detects "1. Question" format
    const quizOptionPattern = /^[A-D]\.\s.*$/m; // Detects "A. Option" format
    const sentencePattern = /^\d+\.\s".*"\s\(.+\)$/m; // Detects sentence structure like "Ech huelen eng _____." (I am taking a _____.)

    // Check for standard quiz format
    if (
      quizQuestionPattern.test(response) &&
      quizOptionPattern.test(response)
    ) {
      return `<pre>${response}</pre>`;
    }

    // Check for sentence-based quiz format
    if (quizQuestionPattern.test(response) && sentencePattern.test(response)) {
      return `<pre>${response}</pre>`;
    }

    // If no specific format detected, return as is
    return response;
  }

  const handleSend = (e) => {
    e.preventDefault();
    if (input !== "" && input.trim()) {
      if (id) {
        let text = ``;
        if (chapter !== "") {
          text = `from ${chapter} file.`;
        }
        if (input.trim()) {
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              message: `<p>${input}</p>`,
              sendBy: "You",
              createdAt: new Date(),
            },
          ]);
          socket.emit("ask-question", { message: input, chatId: id });
          setInput("");
        }
      } else {
        // handleNewChat()
        handleCreateChat();
      }
    }
  };

  useEffect(() => {
    if (socket) {
      const handleResponse = (data) => {
        const resp = formatQuizOrReturnNormal(data);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: resp,
            sendBy: "AI",
            createdAt: new Date(),
          },
        ]);
      };

      socket.on("connect", () => {
        console.log("Socket connected with ID:", socket.id);
        if (shouldFetch.current) {
          shouldFetch.current = false; // Ensure the message is only sent once
        }
      });

      socket.on("disconnect", () => {
        console.log("Socket disconnected.");
      });

      // Add listener for "question-response"
      socket.on("question-response", handleResponse);
      socket.on("chat-created", (newChat) => {
        setMessages([]);
        setId(newChat._id)
        navigate(`/chat/${newChat._id}` , {replace : true});
        if (input) {
          alert(input);
          setMessages((prevMessages) => [
            ...prevMessages,
            {
              message: `<p>${input}</p>`,
              sendBy: "You",
              createdAt: new Date(),
            },
          ]);
          socket.emit("ask-question", { message: input, chatId: newChat._id });
          setInput("");
        }
        const NewChat = {
          _id: newChat._id,
          userId: "674eeaf1c269b36d3a4bf03e",
          lastMessage: {
            __v: 0,
            _id: "675fc81850a5e90270804c7c",
            "​chatId": "675fc80550a5e90270804c6b",
            createdAt: new Date(),
            message: "New chat created",
            sendBy: "You",
            updatedAt: new Date(),
          },
        };
        setAllChats((prevChats) => [NewChat, ...prevChats]);
      });

      // Cleanup listener on component unmount
      return () => {
        socket.off("question-response", handleResponse);
        socket.off("connect");
        socket.off("chat-created");
        socket.off("disconnect");
      };
    }
  }, []);

  const token = localStorage.getItem("token");

  const handleGetAllMessages = useCallback(
    async (id) => {
      setMessages([]);
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: token,
        };
        const response = await apiService(
          `${BASE_URL}/message/${id}`,
          "GET",
          {},
          headers
        );
        console.log(response);

        if (response.status === true) {
          setMessages(response.data);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error(error.response?.message || "Error fetching messages");
      }
    },
    [token]
  ); // Dependencies needed inside handleGetAllMessages

  const handleCreateChat = useCallback(async () => {
    // // setMessages([]);
    const userInput = input
    setMessages([
      {
        message: `<p>${input}</p>`,
        sendBy: "You",
        createdAt: new Date(),
      },
    ]);
    setInput('')
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      const response = await apiService(
        `${BASE_URL}/chat`,
        "POST",
        {},
        headers
      );
      if (response.status) {
        const chatId = response.data._id;
        setId(chatId)
          socket.emit("ask-question", { message: userInput, chatId: chatId });
          navigate(`/chat/${chatId}` , { replace: true });
        const NewChat = {
          _id: chatId,
          userId: currentUser.ˍid,
          lastMessage: {
            __v: 0,
            _id: "675fc81850a5e90270804c7c",
            "​chatId": "675fc80550a5e90270804c6b",
            createdAt: new Date(),
            message: "New chat created",
            sendBy: "You",
            updatedAt: new Date(),
          },
        };
        setAllChats((prevChats) => [NewChat, ...prevChats]);
      }
    } catch (error) {
      toast.error(error.response?.message || "Error fetching messages");
    }
  });

  const handleConversations = useCallback(async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: token,
      };
      const response = await apiService(`${BASE_URL}/chat`, "GET", {}, headers);
      console.log(response);

      if (response.status === true) {
        setAllChats(response.data);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.response?.message || "Error fetching messages");
    }
  }, [token]);

  useEffect(() => {
    if (id && messages.length === 0) {
      handleGetAllMessages(id);
    }
  }, [id]); // Dependency array to trigger on path change

  useEffect(() => {
    handleConversations();
  }, []);

  useEffect(() => {
    if (ref.current) {
      console.log(ref.current.scrollTop);
      ref.current.scrollIntoView(ref.current.scrollTop);
    }
  }, [messages]);

  let recognition;

  const handleStartListening = (e) => {
    if (!window.SpeechRecognition && !window.webkitSpeechRecognition) {
      return;
    }

    recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    console.log(recognition);
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.continuous = false;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript;
      const newInput = `${input} ${speechResult}`;
      // setInput((prev) => prev + " " + speechResult);
      // setTimeout(() => {
      //   handleSend(e)
      // }, 1000);
      if (newInput?.trim()) {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            message: `<p>${newInput}</p>`,
            sendBy: "You",
            createdAt: new Date(),
          },
        ]);
        socket.emit("ask-question", { message: newInput, chatId: id });
        setInput("");
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };

    recognition.onend = () => {
      console.log("Speech recognition");
      handleSend(e);
      setIsListening(false);
    };

    recognition.start();
  };

  const handleStopListening = (e) => {
    console.log("Speech recognition stop");
    setIsListening(false);
    handleSend(e);
    recognition?.stop();
  };

  const handleNewChat = () => {
   if(!id || messages.length !== 0){ setInput('')
    setMessages([])
    socket.emit("new-chat", "");}
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const daysDifference = Math.floor((today - date) / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "Today";
    }

    if (daysDifference === 1) {
      return "Yesterday";
    }

    if (daysDifference >= 2 && daysDifference <= 6) {
      return `${daysDifference} Days Ago`;
    }

    return date.toLocaleDateString(); // Fallback to formatted date for older messages
  };

  const groupChatsByDate = (allChats) => {
    const groupedChats = {
      today: [],
      yesterday: [],
      thisWeek: [],
      older: [],
    };

    allChats.forEach((chat) => {
      // Check if lastMessage and createdAt exist
      if (!chat?.lastMessage?.createdAt) {
        console.warn("Chat is missing lastMessage or createdAt:", chat);
        groupedChats.older.push(chat); // Fallback group for invalid chats
        return;
      }
      const formattedDate = formatDate(chat.lastMessage.createdAt);

      // Group the chats based on the formatted date
      if (formattedDate === "Today") {
        groupedChats.today.push(chat);
      } else if (formattedDate === "Yesterday") {
        groupedChats.yesterday.push(chat);
      } else if (formattedDate.includes("Days Ago")) {
        groupedChats.thisWeek.push(chat); // Group by this week (2-6 days ago)
      } else {
        groupedChats.older.push(chat); // For older dates
      }
    });

    return groupedChats;
  };

  const groupedChats = groupChatsByDate(allChats);

  return (
    <div className="min-h-[100vh] overflow-hidden">
      <div className="xs:hidden">
        <Navbar />
      </div>

      <div className="">
        <div className="flex py-10   px-[7%]  w-full h-[89vh]">
          <div className="w-[25%]   xs:max-w-[25%] bg-[#00A3E0]  flex flex-col items-center  h-full   px-[3%] scrollbar-hide  rounded-tl-3xl rounded-bl-3xl  pt-0 overflow-y-auto xs:hidden">
            <div className="py-2  w-full bg-[#00A3E0]  sticky top-0">
              <div
                className="w-full flex flex-wrap justify-between  lg:flex-row lg:flex-nowrap md:flex-nowrap  md:flex-row md:gap-2 lg:gap-4 lg:px-2    sm:flex-col sm:py-3 xs:py-3 sm:gap-2 text-[11px] items-center md:px-1 mt-4 xl:px-4 text-[#00A3E0] font-inter font-semibold rounded-xl border-0 lg:py-4 md:py-3 xl:py-5 bg-white"
                // onClick={onClick}
              >
                <div className="flex gap-[6px] font-inter items-center text-[11px]">
                  <IoChatbubbleSharp />
                  CHATS
                  <p className="bg-[#DBF1EC] font-inter mb-0 px-1 pt-[0.6px] pb-0 text-[11px] rounded-[4px]">
                    {" "}
                    24
                  </p>
                </div>
                <div className="flex  gap-[6px] font-inter  text-[11px] items-center">
                  <p className="text-[#3B3B3B] font-inter flex items-center text-[11px] gap-2">
                    <FaBookmark />
                    SAVED
                    <p className="bg-[#DBF1EC] font-inter mb-0 px-1 pt-[0.6px] pb-0 text-[11px] rounded-[4px]">
                      {" "}
                      24
                    </p>
                  </p>
                </div>
              </div>
              <div className="pt-2 relative">
  <IoSearch className="absolute top-[17px] left-[6px] h-[20px] w-[20px]" />
  <input
    type="search"
    name=""
    id=""
    placeholder="Search..."
    className="w-full ps-8 text-[#575B65] text-[15px] rounded-lg border-0 h-[35px] bg-white focus:outline-none focus:ring-0 hover:ring-0 hover:bg-white"
  />
</div>

            </div>

            <div className="flex pb-4 w-full flex-col gap-8    h-[450px] overflow-y-auto scrollbar-hide">
              {groupedChats.today.length > 0 && (
                <div className="bg-white pb-2  py-2 rounded-2xl ">
                  <h2 className="font-normal px-3 py-2 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                    Today
                  </h2>
                  {groupedChats.today.map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setId(chat?._id)
                        setMessages([])
                        navigate(`/chat/${chat?._id}`)}}
                    >
                      {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                      <div className="">
                        <div
                          className={`flex ${
                            chat.lastMessage.sendBy === "You"
                              ? "justify-end"
                              : "justify-start"
                          }`}
                        >
                          <div
                            key={index}
                            className={`w-full cursor-pointer py-2 px-3 ${
                              index === groupedChats.today.length - 1
                                ? "border-b-0 border-0 "
                                : "border-b border-[#F4F4F4]"
                            } ${
                              chat.lastMessage.sendBy === "You"
                                ? "text-black"
                                : "bg-gray-200 text-black"
                            }`}
                          >
                            <p className="truncate text-[13px] font-inter font-normal max-w-full">
                              {chat.lastMessage.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {groupedChats.yesterday.length > 0 && (
                <div className="bg-white py-2  pb-2 rounded-2xl">
                  <h2 className="font-normal  px-3  py-2 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                    Yesterday
                  </h2>
                  {groupedChats.yesterday.map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setId(chat?._id)
                        setMessages([])
                        navigate(`/chat/${chat?._id}`)}}
                    >
                      {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                      <div className="">
                        <div
                          className={`flex ${
                            chat.lastMessage.sendBy === "You"
                              ? "justify-end"
                              : "justify-start"
                          }`}
                        >
                          <div
                            key={index}
                            className={`w-full cursor-pointer py-2 px-3 ${
                              index === groupedChats.yesterday.length - 1
                                ? "border-b-0 "
                                : "border-b border-[#F4F4F4]"
                            } ${
                              chat.lastMessage.sendBy === "You"
                                ? "text-black"
                                : "bg-gray-200 text-black"
                            }`}
                          >
                            <p className="truncate text-[13px] font-inter font-normal max-w-full">
                              {chat.lastMessage.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {groupedChats.thisWeek.length > 0 && (
                <div className="bg-white py-2 pb-2  rounded-2xl">
                  <h2 className="font-normal px-3 py-2 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                    Previous 7 Days{" "}
                  </h2>
                  {groupedChats.thisWeek.map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setId(chat?._id)
                        setMessages([])
                        navigate(`/chat/${chat?._id}`)}}
                    >
                      {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                      <div className="">
                        <div
                          className={`flex ${
                            chat.lastMessage.sendBy === "You"
                              ? "justify-end"
                              : "justify-start"
                          }`}
                        >
                          <div
                            key={index}
                            className={`w-full cursor-pointer py-2 px-3 ${
                              index === groupedChats.thisWeek.length - 1
                                ? "border-b-0 "
                                : "border-b border-[#F4F4F4]"
                            } ${
                              chat.lastMessage.sendBy === "You"
                                ? "text-black"
                                : "bg-gray-200 text-black"
                            }`}
                          >
                            <p className="truncate text-[13px] font-inter font-normal max-w-full">
                              {chat.lastMessage.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {groupedChats.older.length > 0 && (
                <div className="bg-white py-2 pb-2   rounded-2xl">
                  <h2 className="font-normal  py-2 px-3 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                    Older
                  </h2>
                  {groupedChats.older.map((chat, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setId(chat?._id)
                        setMessages([])
                        navigate(`/chat/${chat?._id}`)}}
                    >
                      {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                      <div className="">
                        <div
                          className={`flex ${
                            chat.lastMessage.sendBy === "You"
                              ? "justify-end"
                              : "justify-start"
                          }`}
                        >
                          <div
                            key={index}
                            className={`w-full cursor-pointer py-2 px-3 ${
                              index === groupedChats.older.length - 1
                                ? "border-b-0 "
                                : "border-b border-[#F4F4F4]"
                            } ${
                              chat.lastMessage.sendBy === "You"
                                ? "text-black"
                                : "bg-gray-200 text-black"
                            }`}
                          >
                            <p className="truncate text-[13px] font-inter font-normal max-w-full">
                              {chat.lastMessage.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>



          {/* <div className="xs:flex w-[50%]  justify-enditems-center hidden absolute top-4  z-50"
         ref={sidebarRef}  >
  <button onClick={toggleMenu}  className="text-[#6D737A] fixed max-w-[40px] "  >
    {isOpenn ? <FaTimes size={24} className="left-0 "  /> : <FaBars size={24}  className="top-2  relative " />}
  </button>
 
  
</div> */}
<div className="xs:flex  hidden absolute top-4 z-50">
    {isOpenn ? (
      <div className="flex absolute  left-0 top-0  ">
          <FaTimes size={24} className="text-white " onClick={toggleMenu} />
        </div>
    ) : (
      <div className="border-b mt-2   w-[90vw]  flex items-center justify-between  pb-[8px]  absolute  left-0 border-[#E7E9EB]">
        <FaBars size={24} className=" relative" onClick={toggleMenu} />
        <div>
          <Link to="/">
          <img src={logo} alt="" className="w-24 "/>
          </Link>
        </div>
      </div>
    )}
</div>




          {isOpenn && (

            <div className="fixed  bg-[#00A3E0] xs:px-4 xs:w-2/3 inset-0  z-40 overflow-y-auto"
            ref={sidebarRef} >
              <div className="w-[100%]  py-4 px-[3%] scrollbar-hide  rounded-tl-3xl rounded-bl-3xl min-h-full overflow-y-auto ">
                <div className="mt-3  mb-3  w-full   sticky ">
                  <div
                    className="w-full flex px-2 flex-wrap justify-between  lg:flex-row lg:flex-nowrap md:flex-nowrap  md:flex-row md:gap-2 lg:gap-4 lg:px-4    sm:flex-col sm:py-3 xs:py-3 sm:gap-2 text-[11px] items-center md:px-1 mt-4 xl:px-4 text-[#00A3E0] font-inter font-semibold rounded-xl border-0 lg:py-4 md:py-3 xl:py-5 bg-white"
                    // onClick={onClick}
                  >
                    <div className="flex gap-[6px] font-inter items-center text-[11px]">
                      <IoChatbubbleSharp />
                      CHATS
                      <p className="bg-[#DBF1EC] font-inter mb-0 px-1 pt-[0.6px] pb-0 text-[11px] rounded-[4px]">
                        {" "}
                        24
                      </p>
                    </div>
                    <div className="flex  gap-[6px] font-inter  text-[11px] items-center">
                      <p className="text-[#3B3B3B] font-inter flex items-center text-[11px] gap-2">
                        <FaBookmark />
                        SAVED
                        <p className="bg-[#DBF1EC] font-inter mb-0 px-1 pt-[0.6px] pb-0 text-[11px] rounded-[4px]">
                          {" "}
                          24
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="pt-2 relative">
  <IoSearch className="absolute top-[17px] left-[6px] h-[20px] w-[20px]" />

  <input
    type="search"
    name=""
    id=""
    placeholder="Search..."
    className="w-full ps-8 text-[#575B65] text-[15px] rounded-lg border-0 h-[35px] bg-white focus:outline-none focus:ring-0 hover:ring-0 hover:bg-white"
  />
</div>

                </div>

                <div className="flex  flex-col gap-8  min-h-full  max-h-[600px] overflow-y-auto scrollbar-hide">
                  {groupedChats.today.length > 0 && (
                    <div 
                    
                    className="bg-white py-2 pb-2 rounded-2xl ">
                      <h2 className="font-normal  px-3 py-2 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                        Today
                      </h2>
                      {groupedChats.today.map((chat, index) => (
                        <div
                        
                          key={index}
                          onClick={() => {
                            toggleMenu()
                            setId(chat?._id)
                            setMessages([])
                            navigate(`/chat/${chat?._id}`)}}
                        >
                          {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                          <div className="">
                            <div
                              key={index}
                              className={`w-full cursor-pointer py-2 px-3 ${
                                index === groupedChats.today.length - 1
                                  ? "border-b-0 "
                                  : "border-b border-[#F4F4F4]"
                              } ${
                                chat.lastMessage.sendBy === "You"
                                  ? "text-black"
                                  : "bg-gray-200 text-black"
                              }`}
                            >
                              <p className="truncate text-[13px] font-inter font-normal max-w-full">
                                {chat.lastMessage.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {groupedChats.yesterday.length > 0 && (
                    <div className="bg-white py-2 pb-2 rounded-2xl">
                      <h2 className="font-normal px-3  py-2 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                        Yesterday
                      </h2>
                      {groupedChats.yesterday.map((chat, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            toggleMenu()
                            setId(chat?._id)
                            setMessages([])
                            navigate(`/chat/${chat?._id}`)}}
                        >
                          {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                          <div className="">
                            <div
                              key={index}
                              className={`w-full cursor-pointer py-2 px-3 ${
                                index === groupedChats.yesterday.length - 1
                                  ? "border-b-0 "
                                  : "border-b border-[#F4F4F4]"
                              } ${
                                chat.lastMessage.sendBy === "You"
                                  ? "text-black"
                                  : "bg-gray-200 text-black"
                              }`}
                            >
                              <p className="truncate text-[13px] font-inter font-normal max-w-full">
                                {chat.lastMessage.message}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {groupedChats.thisWeek.length > 0 && (
                    <div className="bg-white py-2  pb-2 rounded-2xl ">
                      <h2 className="font-normal px-3 py-2 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                        Previous 7 Days{" "}
                      </h2>
                      {groupedChats.thisWeek.map((chat, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            toggleMenu()
                            setId(chat?._id)
                            setMessages([])
                            navigate(`/chat/${chat?._id}`)}}
                        >
                          {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                          <div className="">
                            <div
                              className={`flex ${
                                chat.lastMessage.sendBy === "You"
                                  ? "justify-end"
                                  : "justify-start"
                              }`}
                            >
                              <div
                                key={index}
                                className={`w-full cursor-pointer py-2 px-3 ${
                                  index === groupedChats.thisWeek.length - 1
                                    ? "border-b-0 "
                                    : "border-b border-[#F4F4F4]"
                                } ${
                                  chat.lastMessage.sendBy === "You"
                                    ? "text-black"
                                    : "bg-gray-200 text-black"
                                }`}
                              >
                                <p className="truncate text-[13px] font-inter font-normal max-w-full">
                                  {chat.lastMessage.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {groupedChats.older.length > 0 && (
                    <div className="bg-white py-2 pb-2 rounded-2xl">
                      <h2 className="font-normal  py-2 px-3 font-inter flex justify-start text-[#1C1C1C66] border-b border-l-0 border-r-0 text-[15px]">
                        Older
                      </h2>
                      {groupedChats.older.map((chat, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            toggleMenu()
                            setId(chat?._id)
                            setMessages([])
                            navigate(`/chat/${chat?._id}`)}}
                        >
                          {/* <div className="font-medium w-full text-black rounded-xl text-xl">
                <small>{formatDate(chat.lastMessage.createdAt)}</small>
              </div> */}
                          <div className="">
                            <div
                              className={`flex ${
                                chat.lastMessage.sendBy === "You"
                                  ? "justify-end"
                                  : "justify-start"
                              }`}
                            >
                              <div
                                key={index}
                                className={`w-full cursor-pointer py-2 px-3 ${
                                  index === groupedChats.older.length - 1
                                    ? "border-b-0 "
                                    : "border-b border-[#F4F4F4]"
                                } ${
                                  chat.lastMessage.sendBy === "You"
                                    ? "text-black"
                                    : "bg-gray-200 text-black"
                                }`}
                              >
                                <p className="truncate text-[13px] font-inter font-normal max-w-full">
                                  {chat.lastMessage.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="w-10/12 xs:mt-10 flex items-center justify-center h-[100%] overflow-y-auto scrollbar-hide   bg-[#EEFAFF] xs:rounded-tl-3xl xs:rounded-bl-3xl rounded-tl-none rounded-bl-none rounded-3xl  flex-col  xs:w-full ">
            <div className=" w-full px-6 py-6  overflow-scroll   scrollbar-hide h-[90%]">
              <div className="" ref={ref}>
                {messages?.map((message, index) => (
                  <div
                    key={index}
                    className={`mb-2 flex flex-col gap-1 ${
                      message.sendBy === "You" ? "text-right" : ""
                    }`}
                  >
                    <div
                      className={`flex gap-2 items-center ${
                        message.sendBy === "You"
                          ? "flex-row-reverse"
                          : "flex-row"
                      }`}
                    >
                      <strong>{message.sendBy}</strong>{" "}
                      <small>
                        {new Date(message.createdAt).toLocaleTimeString()}
                      </small>
                    </div>
                    <div
                      className={`messageBox ${
                        message.sendBy === "You" ? "ml-auto" : ""
                      }`}
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    ></div>
                  </div>
                ))}
                {messages?.length === 0 && (
                  <div className="w-full h-full flex justify-center">
                    <div className="rounded-full p-1 xs:mt-2 mt-16 w-auto">
                      {/* <Link to='/' className="w-full flex justify-center"> 
                <img src={logo} alt="" className="max-w-[100px]"/>
                </Link> */}
                      <div>
                        <p className="flex pt-16 xs:pt-6 flex-col gap-2 items-center font-inter">
                          <img src={example} alt="" /> Example
                        </p>
                        <div className="pt-4 flex flex-col gap-3">
                          <span className="bg-white font-inter text-[15px] rounded-lg py-1 px-2 text-[#1C1C1C]">
                            How do I say 'Good Morning' in Luxembourgish?
                          </span>
                          <span className="bg-white font-inter text-[15px] rounded-lg py-1 px-2 text-[#1C1C1C]">
                            What are common greetings in Luxembourgish
                          </span>
                          <span className="bg-white font-inter text-[15px] rounded-lg py-1 px-2 text-[#1C1C1C]">
                            What is the Luxembourgish word for 'Thank you’
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="py-2 w-full mb-6 xs:h-[25%]  h-[10%]">
              {id ? (
                <div>
                  <div className="w-full xs:flex-col gap-3 px-[3%] flex items-center justify-center">
                    <div className="xs:flex xs:w-full xs:justify-between ">
                      <button
                        onClick={handleNewChat}
                        className="px-4   xs:px-2  xs:gap-0 xs:justify-start py-2 md:px-2 lg:px-2 text-[13px] md:text-[11px]  lg:text-[11px] rounded-full flex gap-2  items-center  bg-[#00A3E0] text-white"
                      >
                        <img src={plus} alt="" className="w-[20px] h-[20px]" />
                        New Chat
                      </button>
                      <span className="xs:flex hidden  gap-2">
                        <img
                          src={mike}
                          alt="microphone"
                          onClick={handleStartListening} // Toggle start/stop based on isListening
                          className={` cursor-pointer h-[45px] animate-pulse`}
                        />
                        {isListening && !isPaused && (
                          <button
                            onClick={handleStopListening}
                            className="ml-2 text-sm text-yellow-500"
                          >
                            Pause
                          </button>
                        )}
                        {isListening && !isPaused && (
                          <p className="mt-4 text-sm text-blue-500 animate-bounce"></p>
                        )}
                        <img
                          src={gallery}
                          alt=""
                          className="w-[45px] h-[45px] "
                        />
                      </span>
                    </div>

                    <span className="flex  xs:hidden gap-2">
                      <button
                        className={`relative w-[45px] h-[45px] xs:w-[35px] xs:h-[35px] rounded-full overflow-hidden border-4 ${
                          isListening ? "border-blue-500" : "border-gray-300"
                        } flex items-center justify-center focus:outline-none ${
                          isListening && !isPaused
                            ? "bg-blue-500"
                            : "bg-gray-200"
                        }`}
                      >
                        <img
                          src={mike}
                          alt="microphone"
                          onClick={handleStartListening} // Toggle start/stop based on isListening
                          className={` cursor-pointer h-[45px]  "animate-pulse" : ""}`}
                        />

                        {/* Animated Recording Indicator */}
                        {isListening && !isPaused && (
                          <span className="absolute w-[15px] h-[15px] rounded-full bg-red-500 animate-ping"></span>
                        )}
                      </button>
                      {isListening && !isPaused && (
                        <button
                          onClick={handleStopListening}
                          className="ml-2 text-sm text-yellow-500"
                        >
                          Pause
                        </button>
                      )}
                      {isListening && !isPaused && (
                        <p className="mt-4 text-sm text-blue-500 animate-bounce"></p>
                      )}

                      <img
                        src={gallery}
                        alt=""
                        className="w-[45px] h-[45px]  xs:w-[35px] xs:h-[35px]"
                      />
                    </span>

                    <div className="w-[60%] xs:w-full  relative">
                      <IoSearch className="absolute top-1/2 left-[21px] h-[20px] w-[20px] transform -translate-y-1/2 text-[#1C1C1C]" />
                      <form onSubmit={handleSend} className="flex gap-2">
                        <input
                          type="search"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          placeholder="Type a message"
                          className="w-full pl-12 xl:py-4 lg:py-3 md:py-3 sm:py-3 xs:py-3 bg-white text-[#1C1C1C] placeholder-[#1C1C1C66] rounded-full border-0 focus:outline-none"
                        />
                        <button className="flex " type="submit">
                          <img
                            src={send}
                            onClick={handleSend}
                            alt=""
                            className="min-w-[45px] min-h-[45px] cursor-pointer  "
                          />
                        </button>
                       
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="w-full  xs:flex-wrap gap-3 px-[3%] flex items-center justify-center">
                    <div className="xs:w-full  xs:justify-between xs:gap-6 xs:flex ">
                      <button
                        onClick={handleNewChat}
                        className="px-4  xs:px-2  xs:gap-0 py-2 xs:justify-center md:px-2 lg:px-2 text-[13px] md:text-[11px]  lg:text-[11px] rounded-full flex gap-2  items-center  bg-[#00A3E0] text-white"
                      >
                        <img src={plus} alt="" className="w-[20px] h-[20px]" />
                        New Chat
                      </button>
                      <span className=" xs:flex xs:justify-end  hidden   gap-2">
                        <img
                          src={mike}
                          alt="microphone"
                          onClick={handleStartListening} // Toggle start/stop based on isListening
                          className="w-[45px] cursor-pointer min-w-[45px] min-h-[45px]  xs:h-[35px]"
                        />
                        {isListening && !isPaused && (
                          <button
                            onClick={handleStopListening}
                            className="ml-2 text-sm text-yellow-500"
                          >
                            Pause
                          </button>
                        )}
                        {isListening && !isPaused && (
                          <p className="mt-4 text-sm text-blue-500 animate-bounce">listing.....</p>
                        )}
                        <img
                          src={gallery}
                          alt=""
                          className="min-w-[45px] min-h-[45px]   xs:w-[35px] xs:h-[35px]"
                        />
                      </span>
                    </div>
                    <span className="flex xs:hidden   gap-2">
                      <button
                        className={`relative w-[45px] h-[45px]  xs:w-[35px] xs:h-[35px] rounded-full overflow-hidden border-4 ${
                          isListening ? "border-blue-500" : "border-gray-300"
                        } flex items-center justify-center focus:outline-none`}
                      >
                        <img
                          src={mike}
                          alt="microphone"
                          onClick={handleStartListening}
                          className={` cursor-pointer h-[45px]   "animate-pulse" : ""}`}
                        />
                      </button>
                      {isListening && !isPaused && (
                        <button
                          onClick={handleStopListening}
                          className="ml-2 text-sm text-yellow-500"
                        >
                          Pause
                        </button>
                      )}
                      {isListening && !isPaused && (
                        <p className="mt-4 text-sm text-blue-500 animate-bounce"></p>
                      )}

                      <img
                        src={gallery}
                        alt=""
                        className="w-[45px] h-[45px]  xs:w-[35px] xs:h-[35px]"
                      />
                    </span>

                    <div className="w-[60%] xs:flex  xs:gap-6 xs:w-full relative">
                      <IoSearch className="absolute top-1/2 left-[21px] h-[20px] w-[20px] transform -translate-y-1/2 text-[#1C1C1C]" />
                      <form onSubmit={handleSend} className="flex xs:w-full xs:justify-between gap-2">
                        <input
                          type="search"
                          value={input}
                          onChange={(e) => setInput(e.target.value)}
                          placeholder="Type a message"
                          className="w-full pl-12 pr-4 xl:py-4 lg:py-3 md:py-3 sm:py-3 xs:py-3 bg-white text-[#1C1C1C] placeholder-[#1C1C1C66] rounded-full border-0 focus:outline-none"
                        />
                        <button type="submit" className="xs:flex hidden">
                          <img
                            src={send}
                            onClick={handleSend}
                            alt=""
                            className="min-w-[45px] min-h-[45px] cursor-pointer  "
                          />
                        </button>
                        <button className="xs:hidden">
                          <img
                            src={send}
                            onClick={handleSend}
                            alt=""
                            className="w-[45px] h-[45px] cursor-pointer  xs:w-[35px] xs:h-[35px]"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;