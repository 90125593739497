import React from 'react'
import img from "../../assets/Svg/choose.svg"
import img1 from "../../assets/Svg/lessonimg1.svg"
import img2 from "../../assets/Svg/robot.svg"
import img3 from "../../assets/Svg/track.svg"
import arrow from "../../assets/Svg/Arrowchoose.svg"
import { MdArrowOutward } from "react-icons/md";
const Choose = () => {
  return (
    <div className='md:px-[5%]  xl:px-[6%]  sm:px-[6%] xs:px-[4%] lg:px-[7%] '>
  <div className='xs:pt-6  lg:py-12 xl:py-10 md:py-6'>
        <div className='relative'>
            <h1 className='text-[35px]  xs:text-[28px] sm:text-[30px] md:text-[33px]  font-semibold font-publicSans'>Why <span className='text-[#00A3E0] '> Choose Us?</span></h1>
            <img src={img} alt=""  className='absolute ps-16 '/>
            <p className='text-[#737373] text-[16px] py-3 font-publicSans'>Your Gateway to Learning Luxembourgish – Easy, Engaging, Effective!</p>
        </div>
        <div className='w-full py-10 xs:flex-col xs:items-center xs:gap-6 lg:gap-10 xl:gap-10 md:gap-8 sm:gap-6 flex justify-between'>
            <div className='w-[33%]   xs:w-[90%]   p-3 border flex flex-col justify-between  shadow-sm rounded-sm'>
                <div>
                <h1 className='font-publicSans sm:flex-col xs:flex-col font-semibold text-[20px] items-center flex gap-4'><img src={img1} alt="" className='w-8 h-8' /> Curriculum-Aligned Lessons</h1>
                <p className='py-4 text-[#6D737A] font-publicSans '>Learn step-by-step with structured lessons matching classroom instruction.</p>
                </div>
           
                <img src={arrow} alt=""  className='max-w-[25%]'/>            </div>
            <div className='w-[33%] xs:w-[90%]  p-3 border flex flex-col justify-between  shadow-sm rounded-sm'>
                <div>
                <h1 className='font-publicSans sm:flex-col xs:flex-col font-semibold text-[20px] items-center flex gap-4'><img src={img2} alt="" className='w-8 h-8' /> AI-Powered Chat Assistant</h1>
             <p className='py-4 text-[#6D737A] font-publicSans '>Get instant help, explanations, and translations in your native language.
             </p>
                </div>
             
            
                <img src={arrow} alt=""  className='max-w-[25%]'/>           

            </div>
            <div className='w-[33%] xs:w-[90%]  p-3 border  flex flex-col justify-between shadow-sm rounded-sm'>
                <div>
                    <h1 className='font-publicSans xs:flex-col sm:flex-col font-semibold text-[20px] items-center flex gap-4'><img src={img3} alt="" className='w-8 h-8' /> Test Yourself!</h1>
                <p className='py-4 text-[#6D737A] font-publicSans '>Ask the AI tutor to test you on specific classes or rules! This is a great way to check your progress.</p>
                </div>
            
                <img src={arrow} alt=""  className='max-w-[25%]'/>
            </div>
        </div>
    </div>
    </div>
  
  )
}

export default Choose