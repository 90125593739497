import React from "react";
import Hero from "../HeroSection/Hero";
import Choose from "../Chooseus/Choose";
import Instructor from "../Instructor/Instructor";
import Achievement from "../Achievments/Achievments";
import Navbar from "./Navbar";
import Teacher from "../JoinTeacher/Teacher";
import Feedback from "../Feedback/Feedback";
import Footer from "../Footer/Footer";
import LearningHero from "../Learning/Learning";
import Cara from "../Carasoul/carsouel";
import AboutUs from "../AboutUs";
// import Cara from '../Carasoul/carsouel'

const Main = () => {
  return (
    <>
      <Navbar />
      <Hero />
      {/* <LearningHero /> */}
      <Choose />
      {/* <Instructor /> */}
      {/* <Achievement /> */}
      {/* <Feedback/> */}
      {/* <Cara /> */}
      <Teacher />
      <AboutUs/>
      <Footer />
    </>
  );
};

export default Main;
