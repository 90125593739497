
import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Signup from "./Components/Signup/Signup";
import Login from "./Components/Login/Login";
import Main from "./Components/Main/Main";
import Chat from "./Components/Chat";
import ForgotPassword from "./Components/Forgotpassword/ForgotPassword";
import VerifyEmail from "./Components/VerifyEmail/VerifyEmail";
import RequestPasswordReset from "./Components/LinkVerify/ResetPasswordRequest";
import NoteContext from "./Components/Context/notes/NoteContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
    const token  = localStorage.getItem("token"); 
    const {currentUser} = useContext(NoteContext)

    console.log(`currentUser ${JSON.stringify(currentUser)}`)

    return (
        <Router>
            <Routes>
                {currentUser && currentUser !== null ? (
                    <>
                        <Route path="/chat" element={<Chat />} />
                        <Route path="/chat/:id" element={<Chat />} />
                        <Route path="/profile" element={<h1>Profile Page</h1>} />
                        <Route path="/dashboard" element={<h1>Dashboard Page</h1>} />
                        <Route path="/chat" element={<Navigate to="/chat" />} />
                    </>
                ) : (
                    <>
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/linkverify" element={<RequestPasswordReset />} />
                        <Route path="/forgotPassword" element={<ForgotPassword />} />
                        <Route path="/" element={<Main />} />   
                        <Route path="*" element={<Navigate to="/" />} />
                    </>
                )}
                {/* Common Route */}
                <Route path="/" element={<Main />} />

                <Route path="/verify" element={<VerifyEmail />} />
            </Routes>
        </Router>
    );
}

export default App;